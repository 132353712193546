/**
 * rellax
 */

import Rellax from 'rellax';

 const rellax = {
    cfg: {
        trigger: '.rellax',
    },
  
    init() {
      window.addEventListener('load', () => {
        const rellax = new Rellax(this.cfg.trigger);

      });
    }
  };
  
  export default rellax;
  