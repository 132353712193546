/**
 *  partner
 */
/* eslint-disable */

import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.module'

const partner = {
  cfg: {
    slider: '.js-partner',
    sliderOptions: {
      container: '.js-partner',
      items: 5,
      slideBy: 1,
      edgePadding: 20,
      gutter: 20,
      autoplay: true
    }
  },

  init() {
    //  const sliders = [...document.querySelectorAll(this.cfg.slider)];
    //  if (sliders) {
    //      sliders.forEach((slider) => {
    /* eslint-disable */

    window.addEventListener('load', () => {
      const tinyslider = tns({
        container: '.js-partner',
        items: 2,
        slideBy: 1,
        mode: 'carousel',
        controls: false,
        autoplay: true,
        prevButton: false,
        nextButton: false,
        // edgePadding: 120,
        autoplayTimeout: 4000,
        speed: 1200,
        mouseDrag: true,
        autoplayButtonOutput: false,
        nav: false,
        center: false,
        autoplayButton: false,

        responsive: {
          400: {
            items: 2
          },
          600: {
            items: 2
          },
          800: {
            items: 5
          },
          1000: {
            items: 6
          }
        }
      })
    })
  }
}

export default partner
/* eslint-enable */
