/**
 * microModal
 */
import MicroModal from 'micromodal'

const microModal = {
  init() {
    window.addEventListener('load', () => {
      MicroModal.init()
    })
  }
}

export default microModal
