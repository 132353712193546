/**
 * toggle
 */
/* eslint-disable */
const toggle = {
  cfg: {
    name: 'toggle',
    selectors: {
      toggle: '.js-toggle',
      closers: '.o-pageContent, .o-pageFooter, .o-clickToAction',
    },
  },

  init() {
    const selector = document.querySelector(this.cfg.selectors.toggle);
    const closers = document.querySelectorAll(this.cfg.selectors.closers);
    window.addEventListener('load', () => {
      selector.addEventListener('click', function () {
        document.body.classList.toggle('is-overlayOpen');
          closers.forEach((closer) => {
            closer.addEventListener('click', function (event) {
              document.body.classList.remove('is-overlayOpen');
              document.body.classList.remove('nav-is-open');
            });    
          }
        )
      });
    });
  },
};

export default toggle;
