/**
 * infoBox
 */

const infoBox = {
  cfg: {
    name: `infoBox`
  },
  init() {
    window.addEventListener('load', () => {
      const boxes = [...document.querySelectorAll('[data-infobox]')]

      boxes.forEach((box) => {
        const isOpen = box.dataset.infobox == 'open' ? true : false
        if (isOpen) box.classList.add('is-open')

        box.onclick = function () {
          box.classList.toggle('is-open')
        }
      })
    })
  }
}

export default infoBox
