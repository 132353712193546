// PLUGIN: https://github.com/inorganik/countUp.js
// PLUGIN: https://scrollrevealjs.org/api/reveal.html

import { CountUp } from 'countup.js'
import ScrollReveal from 'scrollreveal'

const counter = {
  cfg: {
    name: 'counter'
  },

  countUp() {
    const counters = [...document.querySelectorAll('[data-counter]')]
    counters.forEach((counter) => {
      const isDecimal = counter.dataset.counter.indexOf(',') >= 0

      var countUp = new CountUp(counter, parseFloat(counter.dataset.counter.replace(/,/, '.')), {
        separator: '.',
        decimal: ',',
        duration: 4,
        decimalPlaces: isDecimal ? 2 : 0
      })
      countUp.start()
    })
  },

  init() {
    window.addEventListener('load', () => {
      ScrollReveal().reveal('.o-counter', { afterReveal: this.countUp, reset: true })
    })
  }
}

export default counter
