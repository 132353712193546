/**
 * Image Slider
 */

// import Flickity from 'flickity-fade'
// import ScrollMagic from 'scrollmagic'
// import { TweenMax, Linear } from 'gsap/all'
// import TweenMax from '../..../../node_modules/gsap/src/uncompressed/TweenMax'
// import animation from '../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'

const pageCover = {
  // cfg: {
  //   slider: '.js-pageCover',
  //   sliderOptions: {
  //     cellAlign: 'left',
  //     contain: true,
  //     draggable: true,
  //     autoPlay: 4500,
  //     pauseAutoPlayOnHover: false,
  //     friction: 1,
  //     lazyLoad: 2,
  //     selectedAttraction: 0.1,
  //     groupCells: 1,
  //     setGallerySize: false,
  //     imagesLoaded: true,
  //     prevNextButtons: false,
  //     pageDots: false,
  //     wrapAround: true,
  //     fade: true
  //     // watchCSS: true
  //   }
  // },

  init() {
    window.addEventListener('load', () => {
      // const sliders = [...document.querySelectorAll(this.cfg.slider)]
      // if (sliders) {
      //   sliders.forEach((slider) => {
      //     const flkty = new Flickity(slider, this.cfg.sliderOptions)
      //     flkty.resize()
      //     setTimeout(() => {
      //       flkty.resize()
      //       slider.classList.add('is-ready')
      //     }, 1000)
      //   })
      // }
    })
  }
}

export default pageCover
